import React from "react";
import "../../styles/Page.scss";
import Layout from "../../components/Layout";
import PageNotBuilt from "../../components/PageNotBuilt";

export default function FeatAuditTrail() {
  return (
    <Layout title="Audit trail">
      <div className="page-body">
        <div className="page-title">
          <h1>Audit trail</h1>
        </div>
        <PageNotBuilt />
      </div>
    </Layout>
  );
}
