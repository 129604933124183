import React from "react";
import "../styles/Page.scss";
import pagenotbuilt from "../images/Warning-amico.svg";

export default function PageNotBuilt() {
  return (
    <div className="page-title">
      <img
        src={pagenotbuilt}
        alt="Page en cours de construction"
        className="small centered"
      />
      <h3 style={{ margin: "20px 0 10px", color: "#30914c", fontSize: 26 }}>
        Toutes nos excuses, cette page est encore en phase de construction...
      </h3>
      <h3>Nous faisons notre possible pour vous aider au plus vite</h3>
      <h3>
        Si vous avez besoin d'aide, contactez-nous à l'adresse{" "}
        <a href="mailto:contact@dotter.science">contact@dotter.science</a>
      </h3>
    </div>
  );
}
